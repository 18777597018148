import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import pinIcon from "../../../svg/pinIcon.svg";
import { PropertyTableProps } from "../config";
import areaLogo from "../../../svg/area-icon.svg";
import { AppDispatch } from "../../../redux/store";
import bedroomLogo from "../../../svg/bedroom.svg";
import bathroomLogo from "../../../svg/bathroom.svg";
import mailBoxIcon from "../../../svg/mailBoxIcon.svg";
import mailBoxIconDisabled from "../../../svg/mailBoxIconDisabled.svg";
import { formatDate } from "../../../helpers/formatDate";
import { addPinnedProperty } from "../../../api/clientsApi";
import {
  formatBudget,
  formatNumberWithDecimals,
} from "../../../helpers/formatBudget";
import mobilePhoneIcon from "../../../svg/mobilePhoneIcon.svg";
import avataPlaceHolder from "../../../svg/dunevilladubai.webp";
import { tokenStorage } from "../../../helpers/storageFunctions";
import tooltipCopyLogo from "../../../svg/tooltip-copy-logo.svg";
import noBrokerIcon from "../../../svg/noBrokerIcon.svg";
import whatsAppIcon from "../../../svg/WhatsAppIconGreen.svg";
import whatsAppIconDisabled from "../../../svg/whatsAppIconDisabled.svg";
import avatarPlaceholder from "../../../helpers/avatarPlaceholder";
import { getValueFromKey } from "../../../helpers/propertyBuildingTypeFormatter";
import style from "./PropertyTable.module.css";
import { normalizeCapitalization } from "../../../helpers/normalizeCapitalization";
import { EmployeesRoleEnum } from "../../../types";

const PropertyTable: React.FC<PropertyTableProps> = ({
  isExporting,
  property,
  onPropertySelect,
  selectedProperties,
  user,
}) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState("");
  const [snackBarSeverity, setSnackBarSeverity] = useState<
    "success" | "error" | "info"
  >();
  const [allowPinnedProperties, setAllowPinnedProperties] =
    useState<boolean>(false);
  const [clientId, setClientId] = useState<number | null>(null);

  const token = tokenStorage().getAccessToken();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  useEffect(() => {
    if (location.pathname.includes("/clients") && id) {
      setAllowPinnedProperties(true);
      setClientId(Number(id));
    } else {
      setAllowPinnedProperties(false);
    }
  }, [location, id]);

  const handleCopy = (text: any) => {
    navigator.clipboard.writeText(text);
    setMessage(`${text} copied!`);
    setSnackBarSeverity("success");
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleOpenBrokerProfile = (id: any) => {
    navigate(`/employees/${id}`);
  };

  const handleOpenClientProfile = (id: any) => {
    navigate(`/clients/${id}`);
  };

  const handlePinProperty = async (propertyId: number) => {
    if (!clientId || !token) {
      return;
    }
    try {
      const response = await dispatch(
        addPinnedProperty(clientId, propertyId, token)
      );

      if (response.status !== 201) {
        return;
      }
      setMessage("Property has been pinned!");
      setSnackBarSeverity("success");
      setOpenSnackbar(true);

      return response;
    } catch (error: any) {
      if (error.response.status === 400) {
        setMessage("This property has already been pinned!");
        setSnackBarSeverity("error");
        setOpenSnackbar(true);
      }
    }
  };

  return (
    <div style={{ marginTop: "16px" }}>
      {property.map((property, index) => {
        const formatedDate = formatDate(property.offerDate);

        const { SBRE, bayut, propertyfinder } = property;

        const status = [SBRE, bayut, propertyfinder].includes("PENDING")
          ? "Publishing"
          : [SBRE, bayut, propertyfinder].includes("APROVED")
          ? "Published"
          : null;

        return (
          <Card
            onClick={() => onPropertySelect(property)}
            className={style.card}
            key={property.id}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              height: "104px",
              marginBottom: "12px",
              boxShadow: "none",
              backgroundColor:
                selectedProperties[0]?.id === Number(property.id)
                  ? "lightgray"
                  : "white",
              border:
                selectedProperties[0]?.id === Number(property.id)
                  ? "2px solid blue"
                  : "1px solid #00000033",
              overflow: "auto",
              transition: "background-color 0.3s",
              opacity: isExporting?.includes(Number(property.id)) ? 0.5 : 1,
              pointerEvents: isExporting?.includes(Number(property.id))
                ? "none"
                : "auto",
              "&:hover": {
                backgroundColor: isExporting?.includes(Number(property.id))
                  ? "inherit"
                  : "#0000000a",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                cursor: isExporting?.includes(Number(property.id))
                  ? "not-allowed"
                  : "pointer",
              }}
            >
              <Box sx={{ position: "relative" }}>
                <CardMedia
                  component="img"
                  sx={{ width: 140, height: "100%" }}
                  image={
                    property.images && property.images.length > 0
                      ? property.images[0]?.url
                      : avataPlaceHolder
                  }
                  alt={property.name}
                />
                {allowPinnedProperties && (
                  <Button
                    sx={{
                      position: "absolute",
                      top: "5px",
                      right: "5px",
                      padding: 0,
                      minWidth: "24px",
                      minHeight: "24px",
                      borderRadius: "100px",
                      background: "#FFFFFF99",
                    }}
                    onClick={(e) => {
                      handlePinProperty(Number(property.id));
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <img src={pinIcon} alt="pin" />
                  </Button>
                )}
              </Box>
              <CardContent
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "0px",
                  height: "104px",
                }}
              >
                <div
                  style={{
                    margin: "12px 0px",
                    padding: "0px 14px",
                  }}
                >
                  <Typography
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <span
                        style={{
                          fontWeight: "400",
                          fontSize: "14px",
                          marginRight: "5px",
                        }}
                      >
                        {property.name},
                      </span>
                      <span
                        style={{
                          fontWeight: "400",
                          fontSize: "12px",
                          color: "#00000099",
                          marginRight: "7px",
                        }}
                      >
                        {`${getValueFromKey(property.buildingType)} for ${
                          property.dealType === "BUY"
                            ? "Buying"
                            : property.dealType === "RENT"
                            ? "Rent"
                            : "Off-plan"
                        }`}
                      </span>
                      <Chip
                        variant="outlined"
                        size="small"
                        label={
                          property.verification !== "PENDING"
                            ? property.status === "AVAILABLE"
                              ? "Vacant"
                              : property.status === "SOLD"
                              ? `Sold on ${formatedDate}`
                              : property.status === "RENTED"
                              ? `Rented till ${formatedDate}`
                              : "Unknown status"
                            : "Pending"
                        }
                        color={
                          property.verification === "PENDING"
                            ? "info"
                            : property.status === "AVAILABLE"
                            ? "success"
                            : property.status === "SOLD"
                            ? "error"
                            : property.status === "RENTED"
                            ? "primary"
                            : "default"
                        }
                      />
                    </div>
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: 1,
                    }}
                  >
                    <LocationOnIcon color="action" />
                    <Typography variant="body2" sx={{ marginLeft: 1 }}>
                      {`Subcommunity/Building ${property.building}, ${
                        property.apartment > 0
                          ? `Apartment ${property.apartment},`
                          : ""
                      } ${property.district.name}`}
                    </Typography>
                  </Box>
                  <Tooltip
                    title={
                      <ul style={{ paddingLeft: "20px" }}>
                        <li
                          style={{
                            display: `${SBRE === null ? "none" : "list-item"}`,
                          }}
                        >
                          {SBRE === "APROVED"
                            ? "Published on SBRE.com"
                            : "SBRE.com - pending"}
                        </li>
                        <li
                          style={{
                            display: `${bayut === null ? "none" : "list-item"}`,
                          }}
                        >
                          {bayut === "APROVED"
                            ? "Published on bayut.com"
                            : "bayut.com - pending"}
                        </li>
                        <li
                          style={{
                            display: `${
                              propertyfinder === null ? "none" : "list-item"
                            }`,
                          }}
                        >
                          {propertyfinder === "APROVED"
                            ? "Published on propertyfinder.ae"
                            : "propertyfinder.ae - pending"}
                        </li>
                      </ul>
                    }
                    componentsProps={{
                      arrow: {
                        sx: {
                          color: "#616161E5",
                        },
                      },
                      tooltip: {
                        sx: {
                          color: "#FFF",
                          backgroundColor: "#616161E5",
                          fontSize: "10px",
                          fontWeight: 500,
                          padding: "8px",
                          boxShadow:
                            "0px 5px 5px -3px #00000033, 0px 8px 10px 1px #00000024, 0px 3px 14px 2px #0000001F",
                        },
                      },
                    }}
                    placement="right"
                    arrow
                  >
                    <Typography
                      sx={{
                        color: "#7E57C2",
                        fontSize: "10px",
                        marginTop: "12px",
                        maxWidth: "75px",
                      }}
                    >
                      {status}
                    </Typography>
                  </Tooltip>
                </div>
              </CardContent>
            </Box>
            <div style={{ display: "flex" }}>
              <Box
                display={"flex"}
                borderLeft={"1px solid #00000033"}
                borderRight={"1px solid #00000033"}
                margin={"12px 0px"}
                padding={"0px 14px"}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  cursor: isExporting?.includes(Number(property.id))
                    ? "not-allowed"
                    : "pointer",
                }}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"space-between"}
                  paddingBlock={"10px"}
                  width={"149px"}
                >
                  <Typography style={{ fontWeight: "500", fontSize: "14px" }}>
                    {`AED ${formatBudget(property.price)}`}
                  </Typography>
                  <Typography style={{ fontWeight: "400", fontSize: "14px" }}>
                    {"ID"} {property.id}
                  </Typography>
                </Box>
                <span
                  style={{
                    borderRight: "1px solid #00000033",
                    marginInline: "14px",
                  }}
                ></span>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"space-between"}
                  paddingBlock={"10px"}
                  width={"165px"}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "32px",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      <img
                        style={{ width: "20px", height: "20px" }}
                        src={bedroomLogo}
                        alt="area pic"
                      />{" "}
                      {property.bedrooms}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      <img
                        style={{ width: "20px", height: "20px" }}
                        src={bathroomLogo}
                        alt="area pic"
                      />{" "}
                      {property.bathrooms}
                    </Typography>
                  </div>
                  <Typography
                    variant="body2"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <img
                      style={{ width: "20px", height: "20px" }}
                      src={areaLogo}
                      alt="area pic"
                    />{" "}
                    {formatNumberWithDecimals(property.area)} {"sqft"}
                    {formatNumberWithDecimals(property.area)} {"sqft"}
                  </Typography>
                </Box>
              </Box>

              {property.broker?.id ? (
                <Box
                  onClick={(e) => {
                    handleOpenBrokerProfile(property.broker?.id);
                    e.stopPropagation();
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    padding: 2,
                    width: "210px",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Avatar
                      alt={`${property.broker?.firstName} ${property.broker?.lastName}`}
                      src={
                        property.broker?.avatarURL
                          ? `${property.broker?.avatarURL}?t=${Date.now()}`
                          : `${avatarPlaceholder(
                              property.broker?.firstName,
                              property.broker?.lastName
                            )}`
                      }
                      sx={{ width: "32px", height: "32px", marginRight: "8px" }}
                    />
                    <div style={{ height: "40px" }}>
                      <Typography>
                        {property.broker?.firstName} {property.broker?.lastName}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {normalizeCapitalization(
                          `${property.broker?.subRole} ${property.broker?.role}`
                        )}
                      </Typography>
                    </div>
                  </div>
                  <Box sx={{ display: "flex", marginTop: 1, gap: "8px" }}>
                    <Tooltip
                      title={
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            handleCopy(property.broker?.phone);
                            e.stopPropagation();
                          }}
                        >
                          <span style={{ marginRight: "4px" }}>
                            {property.broker.phone}
                          </span>
                        </Box>
                      }
                      componentsProps={{
                        tooltip: {
                          sx: {
                            color: "#7E57C2",
                            backgroundColor: "white",
                            fontSize: "12px",
                            padding: "8px",
                            boxShadow:
                              "0px 5px 5px -3px #00000033, 0px 8px 10px 1px #00000024, 0px 3px 14px 2px #0000001F",
                          },
                        },
                      }}
                    >
                      <Button
                        sx={{
                          "&": {
                            border: "1px solid #7E57C2",
                            cursor: "pointer",
                            transition: "background-color 0.3s",
                          },
                          "&:hover": {
                            backgroundColor: "#EDE7F6",
                          },
                        }}
                        onClick={(e) => {
                          handleCopy(property.broker?.phone);
                          e.stopPropagation();
                        }}
                      >
                        <img
                          width={"9px"}
                          height={"15px"}
                          src={mobilePhoneIcon}
                          alt="phone icon"
                        />
                      </Button>
                    </Tooltip>

                    <Tooltip
                      title={
                        <Box
                          onClick={(e) => {
                            handleCopy(property.broker?.email);
                            e.stopPropagation();
                          }}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                        >
                          <span style={{ marginRight: "4px" }}>
                            {property.broker.email}
                          </span>
                          <img
                            src={tooltipCopyLogo}
                            alt="logo"
                            style={{ width: "16px", height: "16px" }}
                          />
                        </Box>
                      }
                      componentsProps={{
                        tooltip: {
                          sx: {
                            color: "#7E57C2",
                            backgroundColor: "white",
                            fontSize: "12px",
                            padding: "8px",
                            boxShadow:
                              "0px 5px 5px -3px #00000033, 0px 8px 10px 1px #00000024, 0px 3px 14px 2px #0000001F",
                          },
                        },
                      }}
                    >
                      <Button
                        onClick={(e) => {
                          handleCopy(property.broker?.email);
                          e.stopPropagation();
                        }}
                        sx={{
                          "&": {
                            border: "1px solid #7E57C2",
                            cursor: "pointer",
                            transition: "background-color 0.3s",
                          },
                          "&:hover": {
                            backgroundColor: "#EDE7F6",
                          },
                        }}
                      >
                        <img
                          width={"15px"}
                          height={"12px"}
                          src={mailBoxIcon}
                          alt="mail icon"
                        />
                      </Button>
                    </Tooltip>

                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      href={property.broker?.whatsAppLink || ""}
                      target={
                        property.broker.whatsAppLink ? "_blank" : undefined
                      }
                      rel={
                        property.broker.whatsAppLink
                          ? "noopener noreferrer"
                          : undefined
                      }
                      sx={{
                        "&": {
                          border: "1px solid #7E57C2",
                          cursor: "pointer",
                          transition: "background-color 0.3s",
                        },
                        "&:hover": {
                          backgroundColor: "#EDE7F6",
                        },
                      }}
                    >
                      <img
                        src={whatsAppIcon}
                        width={"20px"}
                        height={"20px"}
                        alt="WhatsApp icon"
                      />
                    </Button>
                  </Box>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    padding: 2,
                    width: "210px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <img
                      alt="empoyees placeholder"
                      src={noBrokerIcon}
                      style={{ width: "32px", height: "32px" }}
                    />
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "20px",
                      }}
                    >
                      {"Broker not assigned"}
                    </Typography>
                  </div>
                </Box>
              )}
            </div>
          </Card>
        );
      })}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackBarSeverity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default PropertyTable;
